:root {
  --highlight-color: #530CAF;
  --secondary-color: #FF6B35;
}

/* Utility classes */
.text-highlight {
  color: var(--highlight-color);
}

.bg-highlight {
  background-color: var(--highlight-color);
}

.border-highlight {
  border-color: var(--highlight-color);
}

/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

/* App Component Styles */
.App {
  text-align: center;
  background: linear-gradient(45deg, #FCFBFE 30%, #FEF9F9 90%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  position: relative;
}


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px 40px;
  box-sizing: border-box;
}

.landing-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 200px 20px 20px;
  box-sizing: border-box;
}

.landingimage {
  width: 90vw;
  object-fit: contain;
}

.landingtitle {
  font-size: 3rem;
  font-weight: 700;
  color: #000000;
  margin-bottom: 1rem;
}

.landingsubtitle {
  font-size: 14px;
  font-weight: 600;
  color: var(--highlight-color);
  margin-bottom: 1rem;
}

.takesaminute {
  font-size: 12px;
  color: #000000;
  opacity: 0.7;
  margin-bottom: 1rem;
}

.navbarbrand {
  font-size: 16px;
  font-weight: 600;
  color: var(--highlight-color);
}

.navbartry {
  font-size: 14px;
  font-weight: 600;
  color: var(--highlight-color);
}

.getstartedbutton {
  background: var(--highlight-color);
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 50px;
  cursor: pointer;
  border: none;
  width: 200px;
  transition: background-color 0.3s ease;
}

.getstartedbutton:hover {
  background: #4401A8; /* Slightly darker shade for hover */
}

/* MakeDao Component Styles */
.makedao {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.makedaoleft {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.makedaoright {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('./makegamebg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.makedaoform {
  width: 80%;
  max-width: 400px;
}

.imagedroparea {
  width: 100%;
  height: 100px;
  border: 1px dashed var(--highlight-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: var(--highlight-color);
  opacity: 0.6;
}

/* GamePage Component Styles */
.gamepage {
  width: 100%;
  padding: 5% 10%;
  box-sizing: border-box;
}

.gamepagecommunitycard {
  height: 100%;
}

.gamepagecommunitydata {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.gamepagecommunityimage {
  height: 80px;
  border-radius: 10px;
}

.gamepagerespectedmemberimage {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.gamepagecommunitydescription {
  font-size: 14px;
  opacity: 0.8;
}

.gamepagerespectedmembers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  margin-top: 30px;
}

.gamepagerespectedmember {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.prooflinks {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.addproofbutton {
  height: 36px;
  width: 36px;
  border: 1px solid var(--highlight-color);
  color: var(--highlight-color);
  background: white;
  font-size: 20px;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;
}

.addproofbutton:hover {
  background: var(--highlight-color);
  color: white;
}

/* Responsive Styles */
@media (max-width: 960px) {
  .App{
    overflow-x:hidden;
  }
  .gamepage {
    padding: 5%;
  }

  .makedao {
    flex-direction: column;
  }

  .makedaoleft, .makedaoright {
    width: 100%;
  }

  .makedaoright {
    height: 50vh;
  }

  .makedaoform {
    width: 90%;
  }

}

@media (max-width: 600px) {
  
  .landingtitle {
    font-size: 2rem;
  }

  .navbar {
    padding: 10px 20px;
  }

  .getstartedbutton {
    width: 80%;
  }
}

